import { styled } from '@zaritalk/panda-css/jsx';

export default styled('hr', {
  base: {
    width: '100%',
    height: '0px',

    border: 'none',
    borderTop: '1px solid token(colors.$gray02)',
    outline: 'none',
    margin: '0',
    padding: '0',
  },
});
