import { EmojiName, EmojiPosition, TReviewEmojiName } from '@zaritalk/types';

const ZARITALK_STATIC_URL = 'https://static.zaritalk.com';

export const EMOJI = {
  CANCEL_DEPOSIT: `${ZARITALK_STATIC_URL}/emojis/emoji-cancel-deposit%403x.png`, // ⛔
  DEPOSIT: `${ZARITALK_STATIC_URL}/emojis/emoji-deposit%403x.png`, // ✅
  HOUSE: `${ZARITALK_STATIC_URL}/emojis/emoji-house-20-22%403x.png`, // 🏠
  PEOPLE: `${ZARITALK_STATIC_URL}/emojis/emoji-people%403x.png`, // 남녀가 같이 손을 들고 있는
  POST_BILL: `${ZARITALK_STATIC_URL}/emojis/emoji-post-bill%403x.png`, // 💌
  SMILE: `${ZARITALK_STATIC_URL}/emojis/emoji-smile%403x.png`, // 😄
  CALENDAR: `${ZARITALK_STATIC_URL}/emojis/emoji-calendar%403x.png`, // 달력 📅
  KAKAOTALK_YELLOW: `${ZARITALK_STATIC_URL}/emojis/emoji-kakaotalk-yellow-20-20%403x.png`, // 카카오톡
  BELL_WITH_SLASH: `${ZARITALK_STATIC_URL}/emojis/emoji-bell-with-slash%403x.png`, // 줄 그어져있는 종 🔕
  BYE: `${ZARITALK_STATIC_URL}/emojis/emoji-leave%403x.png`, // 바이하는 손 👋
  SECRET_FACE: `${ZARITALK_STATIC_URL}/emojis/emoji-secret-face%403x.png`, // 쉿 하는 얼굴
  MONEY: `${ZARITALK_STATIC_URL}/emojis/emoji-money%403x.png`, // 돈주머니 💰
  ROUND_BELL: `${ZARITALK_STATIC_URL}/emojis/emoji-round-bell%403x.png`, // 동그란 벨 🛎
  BELL: `${ZARITALK_STATIC_URL}/emojis/emoji-bell%403x.png`, // 벨 🔔
  SWEATY_SMILING: `${ZARITALK_STATIC_URL}/emojis/emoji-sweaty-smiling-face%403x.png`, // 땀 흘리며 옷는 얼굴 😅
  ORANGE_BOOK: `${ZARITALK_STATIC_URL}/emojis/emoji-orange-book%402x.png`, // 오렌지색 책 📙
  YOUTUBE: `${ZARITALK_STATIC_URL}/emojis/emoji-youtube%403x.png`, // 유튜브📄
  SHY_SMILE: `${ZARITALK_STATIC_URL}/emojis/emoji-shy-smile@3x.png`, // ☺️
  EYE: `${ZARITALK_STATIC_URL}/emojis/emoji-eye@3x.png`, // 👀
  GRAPH: `${ZARITALK_STATIC_URL}/emojis/emoji-graph@3x.png`, // 📈
  CARD: `${ZARITALK_STATIC_URL}/emojis/emoji-card@3x.png`, // 💳
  BANK: `${ZARITALK_STATIC_URL}/emojis/emoji-bank@3x.png`, // 🏦
  HEART_FILLED: `${ZARITALK_STATIC_URL}/emojis/emoji-heart-filled@3x.png`, // ❤️
};

export const EMOJI_POSITION: Record<EmojiName, EmojiPosition> = {
  SHY_SMILE: { column: 1, row: 1 },
  WINKING_FACE: { column: 2, row: 1 },
  PLEASE_FACE: { column: 3, row: 1 },
  SAD_FACE: { column: 4, row: 1 },
  SECRET_FACE: { column: 5, row: 1 },
  X_FACE: { column: 6, row: 1 },
  LESSEE_SMILE: { column: 7, row: 1 },
  SUNGLASS: { column: 8, row: 1 },
  BIG_SMILE: { column: 9, row: 1 },
  SLY_SMILE: { column: 10, row: 1 },
  HEART_SMILE: { column: 11, row: 1 },
  FROZEN_FACE: { column: 12, row: 1 },
  WOMAN: { column: 13, row: 1 },
  MAN: { column: 14, row: 1 },
  WOMAN_MANAGER: { column: 15, row: 1 },
  WOMAN_TIPPING_HAND: { column: 16, row: 1 },

  PURPLE_DEVIL: { column: 1, row: 2 },
  CAT: { column: 2, row: 2 },
  ALIEN: { column: 3, row: 2 },
  CHICK: { column: 4, row: 2 },
  DOG: { column: 5, row: 2 },
  FOX: { column: 6, row: 2 },
  LESSEE_EMPTY: { column: 7, row: 2 },
  FINGER: { column: 8, row: 2 },
  HAND_CLAP: { column: 9, row: 2 },
  BTN_LEAVE: { column: 10, row: 2 },
  FINGER_TWO: { column: 11, row: 2 },
  FINGER_ONE: { column: 12, row: 2 },
  FINGER_WRITE: { column: 13, row: 2 },
  FOLDED_HANDS: { column: 14, row: 2 },
  HAPPY_HANDS: { column: 15, row: 2 },
  DOWNWARD_HAND: { column: 16, row: 2 },

  HANDSHAKE: { column: 1, row: 3 },
  FINGER_TOUCH: { column: 2, row: 3 },
  FINGER_OK: { column: 3, row: 3 },
  EYES: { column: 4, row: 3 },
  COMMUNITY: { column: 5, row: 3 },
  HOUSE: { column: 6, row: 3 },
  TADA: { column: 7, row: 3 },
  DEPOSIT: { column: 8, row: 3 },
  CANCEL_DEPOSIT: { column: 9, row: 3 },
  CALENDAR: { column: 10, row: 3 },
  PAPER: { column: 11, row: 3 },
  GRAPH: { column: 12, row: 3 },
  POST_BILL: { column: 13, row: 3 },
  ALARM: { column: 14, row: 3 },
  EDIT: { column: 15, row: 3 },
  FIRE: { column: 16, row: 3 },

  ROUND_BELL: { column: 1, row: 4 },
  BELL: { column: 2, row: 4 },
  SPROUT: { column: 3, row: 4 },
  '4CLOVER': { column: 4, row: 4 },
  FLOWER: { column: 5, row: 4 },
  CROWN: { column: 6, row: 4 },
  PUSHPIN: { column: 7, row: 4 },
  RED_PIN: { column: 8, row: 4 },
  STAR: { column: 9, row: 4 },
  LOCK: { column: 10, row: 4 },
  MONEYBAG: { column: 11, row: 4 },
  MONEY: { column: 12, row: 4 },
  CARD: { column: 13, row: 4 },
  FLYING_CASH: { column: 14, row: 4 },
  COFFEE: { column: 15, row: 4 },
  TOOLS: { column: 16, row: 4 },

  BILL: { column: 1, row: 5 },
  MEMO: { column: 2, row: 5 },
  CALCULATOR: { column: 3, row: 5 },
  COMMUNITY2: { column: 4, row: 5 },
  ORANGE_BOOK: { column: 5, row: 5 },
  WARNING: { column: 6, row: 5 },
  GIFT: { column: 7, row: 5 },
  SHIELD: { column: 8, row: 5 },
  MEMO_PENCIL: { column: 9, row: 5 },
  HELP: { column: 10, row: 5 },
  SCREAMING_FACE: { column: 11, row: 5 },
  PERSON_MAN: { column: 12, row: 5 },
  MEGAPHONE: { column: 13, row: 5 },
  TAB: { column: 14, row: 5 },
  ELECTRIC_GUITAR: { column: 15, row: 5 },
  TRUCK: { column: 16, row: 5 },
  BLACK_PHONE: { column: 1, row: 6 },
  BLUE_HEART: { column: 2, row: 6 },
  QUESTION: { column: 3, row: 6 },
  X_BLACK: { column: 4, row: 6 },
  BULB: { column: 5, row: 6 },
  WORRIED_FACE: { column: 6, row: 6 },
  WATER: { column: 7, row: 6 },
  SMILE_FACE: { column: 8, row: 6 },
  BANK: { column: 9, row: 6 },
  HELP_MAN: { column: 10, row: 6 },
  JEWELRY: { column: 11, row: 6 },
  HAPPY_FACE: { column: 12, row: 6 },
};

export const REVIEW_EMOJI_POSITION: { [key in TReviewEmojiName]: EmojiPosition } = {
  BOX: { column: 1, row: 1 },
  TRASHBIN: { column: 2, row: 1 },
  HAMMER: { column: 3, row: 1 },
  APPLE: { column: 4, row: 1 },
  CAR: { column: 5, row: 1 },
  BUG: { column: 6, row: 1 },
  WIND: { column: 7, row: 1 },
  TREE: { column: 8, row: 1 },
  SIREN: { column: 9, row: 1 },
  HOSPITAL: { column: 10, row: 1 },
  SPEAK: { column: 11, row: 1 },
  ELEVATOR: { column: 12, row: 1 },
  SCHOOL: { column: 13, row: 1 },
  HEAT: { column: 14, row: 1 },
  CONVENIENCE_STORE: { column: 15, row: 1 },
  SUBWAY: { column: 16, row: 1 },
  FLAT_LAND: { column: 1, row: 2 },
  SECURITY: { column: 2, row: 2 },
  SPEAKER_ZERO: { column: 3, row: 2 },
  MAN_BLACK_HAIR: { column: 4, row: 2 },
};
