import Head from 'next/head';
import React from 'react';

import { NUMBER_OF_TOTAL_USERS_KOREAN_VERSION } from '@zaritalk/constants';

export default function withBaseHead(Component) {
  return function withBaseHeadRender(props) {
    return (
      <>
        <Head>
          <title>{'자리톡 - 부동산 임대관리 (임대인/세입자)'}</title>
          <meta
            name={'description'}
            content={`${NUMBER_OF_TOTAL_USERS_KOREAN_VERSION}명이 쓰는 부동산 임대관리 앱! 월세 알림 · 월세환급 · 월세 카드결제 · 아파트실거래가조회 · 임대장부 · 임대인 비밀 커뮤니티 등 무료 제공!`}
          />
          <meta
            name={'keywords'}
            content={
              '부동산, 중개, 월세환급, 월세카드결제, 실거래가조회, 임대관리, 아파트, 오피스텔, 원룸, 투룸, 빌라, 월세, 전세, 가격조회, 임대인, 임차인'
            }
          />

          <meta property={'og:type'} content={'website'} />
          <meta property={'og:site_name'} content={'자리톡 - 임대관리 필수앱'} />
          <meta property={'og:title'} content={'자리톡 - 임대관리 필수앱'} />
          <meta
            property={'og:description'}
            content={`${NUMBER_OF_TOTAL_USERS_KOREAN_VERSION}명이 쓰는 부동산 임대관리 앱! 월세 알림 · 월세환급 · 월세 카드결제 · 아파트실거래가조회 · 임대장부 · 임대인 비밀 커뮤니티 등 무료 제공!`}
          />
          <meta
            property={'og:keywords'}
            content={
              '부동산, 중개, 월세환급, 월세카드결제, 실거래가조회, 임대관리, 아파트, 오피스텔, 원룸, 투룸, 빌라, 월세, 전세, 가격조회, 임대인, 임차인'
            }
          />
          <meta property={'og:image'} content={'https://static.zaritalk.com/images/img-zaritalk-thumbnail.png'} />
          <meta property={'og:image:width'} content={'1200'} />
          <meta property={'og:image:height'} content={'630'} />
          <meta property={'og:url'} content={'https://zaritalk.com/'} />
          <meta property={'og:locale'} content={'ko_KR'} />
          <meta property={'al:web:url'} content={'https://zaritalk.com/'} />
        </Head>
        <Component {...props} />
      </>
    );
  };
}
