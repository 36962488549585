import { CSSProperties } from 'react';

import { COLOR } from '@zaritalk/constants';
import { styled } from '@zaritalk/panda-css/jsx';
import { SystemStyleObject } from '@zaritalk/panda-css/types';

type SkeletonProps = {
  count?: number;

  width?: string | number;
  height?: string | number;

  circle?: boolean;
  style?: CSSProperties;
  css?: SystemStyleObject | SystemStyleObject[];
  className?: string;
  theme?: 'LIGHT' | 'DARK';
};

export default function Skeleton({
  count = 1,
  width = '100%',
  height = '30px',
  circle = false,
  className = '',
  css: skeletonCss,
  style,
  theme = 'LIGHT',
}: SkeletonProps) {
  const elements: Array<JSX.Element> = [];

  for (let i = 0; i < count; i++) {
    const initStyle: CSSProperties = {};

    if (width != -1) {
      initStyle['width'] = width;
    }

    if (height != null) {
      initStyle['height'] = height;
    }

    if (width !== -1 && height !== -1 && circle) {
      initStyle['borderRadius'] = '50%';
    }

    if (theme === 'DARK') {
      initStyle['mixBlendMode'] = 'initial';
      initStyle['backgroundColor'] = COLOR.GRAY06;
    }

    elements.push(<SkeletonW key={i} className={className} style={{ ...initStyle, ...style }} css={skeletonCss} />);
  }

  return <>{elements.map((element) => element)}</>;
}

const SkeletonW = styled('span', {
  base: {
    position: 'relative',
    width: '100%',

    backgroundColor: '#eee',
    borderRadius: '15px',
    display: 'inline-block',
    lineHeight: 1,

    isolation: 'isolate',
    overflow: 'hidden',
    mixBlendMode: 'multiply',
    animation: 'skeleton 1.2s ease-in-out infinite',

    willChange: 'opacity',
  },
});
